@import 'bootstrap-custom/layout.scss';

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #838383 #ffffff;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #838383;
  border-radius: 5px;
  border: 3px solid #ffffff;
}
/* ===== End Scrollbar CSS ===== */

.ngx-pagination .current {
  padding: 0.1875rem 0.625rem !important;
  background: #00305e !important;
  color: #fefefe !important;
  cursor: default !important;
  border-radius: 0.25em !important;
}

input,
select,
textarea {
  &.ng-pristine.ng-invalid.ng-touched,
  &.ng-invalid.ng-touched.ng-dirty {
    @extend .is-invalid;
  }
}

i.req {
  color: red;
}

td {
  vertical-align: middle;
}

.modal-auth {
  width: 90vw;
}

@include media-breakpoint-up(md) {
  .modal-auth {
    width: 60vw;
  }
}
@include media-breakpoint-up(lg) {
  .modal-auth {
    width: 40vw;
  }
}
