@import 'bootstrap-custom/_variables';

.primary-color {
  color: $primary;
}

.nav li a:hover {
  border-bottom: 2px solid $primary;
  border-radius: 2px;
}

.control-label,
.form-label {
  font-weight: bold !important;
}

.form-label {
  margin-bottom: 0px;
}

.navigation-btn {
  border-top: 1px solid #dfdfdf;
  padding: 0.5em 0;
}

.btn-secondary {
  color: #000;
  background-color: #d5d5d5;
  border-color: #d5d5d5;

  &:hover,
  &:active,
  &:focus {
    background-color: #7a7a7a;
    border-color: #7a7a7a;
  }
}

.control-label,
.form-label {
  font-weight: 500 !important;
}

.btn-sm {
  height: 25px;
  padding: 0;
  font-size: 13px;
}
