.content-layout-full {
  min-height: 100%;
}
.content-layout-auto {
  min-height: auto;
}
.content-layout {
  @extend .p-2;
  @extend .content-layout-full;
  background: white;
  border-radius: 0.25em;
  &.auto {
    @extend .content-layout-auto;
  }
}
.filters-crud {
  .form-switch {
    min-height: auto;
  }
}
.grid-crud {
  margin-top: 1em;
  min-height: calc(100vh - 7rem);
  height: calc(100vh - 7rem);
}
.title-form-crud {
  border-bottom: 1px solid #bdc3c7;
  p {
    margin-bottom: 0;
    font-weight: bold;
  }
  button {
    width: 0.5em;
    height: 0.5em;
  }
}
