/* You can add global styles to this file, and also import other style files */
@import 'bootstrap-custom/_variables';
@import '~bootstrap/scss/bootstrap';
@import '~ag-grid/dist/styles/ag-grid.css';
@import '~ag-grid/dist/styles/ag-theme-balham.css';
@import '~ngx-toastr/toastr';
@import url('@fortawesome/fontawesome-free/css/all.min.css');
@import '@angular/material/prebuilt-themes/indigo-pink.css';
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.text-align-right {
  text-align: right;
}

@import './assets/styles/root-layout.scss';
@import './assets/styles/cruds-layout.scss';
